/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    em: "em"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "sensorineural-hearing-loss-symptoms-causes--treatment-a-comprehensive-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sensorineural-hearing-loss-symptoms-causes--treatment-a-comprehensive-guide",
    "aria-label": "sensorineural hearing loss symptoms causes  treatment a comprehensive guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sensorineural Hearing Loss: Symptoms, Causes & Treatment: A Comprehensive Guide"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sensorineural hearing loss (SNHL) is the most common form of hearing loss, often caused by natural aging or noise exposure. It’s a result of inner ear or auditory nerve damage and is typically treated with hearing aids or cochlear implants."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A hearing test is the only way to accurately determine the type, severity and subsequent treatment of individual hearing loss, but here we’ll cover the basics so you can better understand what sensorineural hearing loss is, what might cause it and what treatments are available to address it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-sensorineural-hearing-loss",
    "aria-label": "what is sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is sensorineural hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sensorineural hearing loss is one of the three basic categories used by medical professionals to distinguish between different forms of hearing loss and is characterized by damage to the inner ear or auditory nerves."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To put it simply, sensorineural hearing loss occurs when the link between the ear and the brain is damaged or compromised in some way. This happens when the tiny hair cells or nerve endings located inside the cochlea — the spiral-shaped portion of the inner ear — degrade over time or as a result of certain diseases, injuries or medications."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These cells are responsible for turning vibrations caused by sound waves into electrical signals and then carrying those signals to the brain, effectively allowing us to “hear.” When the appropriate signals are no longer delivered to our brains, we may have trouble perceiving certain sounds or tones with clarity, and the severity can range from slight comprehension difficulties to complete hearing loss or deafness."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The other categories of hearing loss are referred to as ", React.createElement(_components.strong, null, "conductive"), " and ", React.createElement(_components.strong, null, "combined (or mixed)"), ", and can be useful to understand as a contrast to sensorineural hearing loss:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-loss/conductive/",
    className: "c-md-a"
  }, "Conductive hearing loss"), " happens as a result of damage or blockage to the outer or middle ear, effectively preventing sound from making its way through the ear canal to the inner ear and auditory nerves."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Combined, or mixed, hearing loss simply refers to some combination of sensorineural and conductive hearing loss."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Elsewhere, you may see sensorineural hearing loss referred to as SNHL (its abbreviation), labyrinthine hearing loss, nerve-related hearing loss, noise-induced hearing loss (NIHL) or age-related hearing loss (presbycusis)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-what-is-sensorineural-hearing-loss-like",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-what-is-sensorineural-hearing-loss-like",
    "aria-label": "symptoms what is sensorineural hearing loss like permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms: What is sensorineural hearing loss like?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The severity of sensorineural hearing loss can vary greatly from person to person. In most cases, it begins mildly; certain sounds or tones may seem softer or harder to distinguish and advances slowly over time, much like vision loss. Symptoms can also vary, so here are some of the most common ways patients describe their experience:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Trouble hearing in noisy places or crowds"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reduced sensitivity to soft or high-pitched sounds"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty distinguishing speech, hearing words or consonants"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Voices seem quiet, muffled or slurred"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Trouble hearing women’s and children’s voices in particular"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Perceiving loud sounds as faint or muffled"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A few less common symptoms include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing or buzzing in the ear (tinnitus)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dizziness or balance issues"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These symptoms almost always appear gradually, but in rare cases, sudden sensorineural hearing loss, or sudden deafness, can occur in one or both ears over the course of a few days or a few hours. If you suspect you or a loved one may be experiencing this kind of hearing loss, sudden or otherwise, the best course of action is always to seek immediate medical attention and care."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "common-causes-of-snhl-aging--noise",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-causes-of-snhl-aging--noise",
    "aria-label": "common causes of snhl aging  noise permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common causes of SNHL: aging & noise"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sensorineural hearing loss can be acquired or inherited. Acquired hearing loss, which happens after an individual is born, is significantly more common, often occurring as a result of one of these two causes:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Natural aging:"), " Also known as presbycusis, ", React.createElement(_components.a, {
    href: "/hearing-loss/presbycusis/",
    className: "c-md-a"
  }, "age-associated hearing loss"), " can begin around the age of 50 or 60 and is especially common in adults over 65. It’s the leading cause of sensorineural hearing loss and is usually accompanied by a reduced perception of high tones."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Noise exposure:"), " Noise-induced hearing loss (NIHL) can happen as a result of a one-time trauma or long-term exposure to loud music or sounds."), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the CDC, NIHL is currently the third most common occupational illness in the United States, affecting up to 22 million Americans. Six to eight hours of noise above 85 decibels on a daily basis is able to cause this type of hearing loss. To put that into perspective, loudness at 85dB is comparable to a lawn mower, a noisy restaurant or school cafeteria, or heavy traffic."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These kinds of sensorineural hearing loss can be prevented or at least delayed to some extent by avoiding loud noises and wearing proper hearing protection. But once damage occurs, it’s permanent and ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "most commonly treated with hearing aids"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "other-causes-of-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-causes-of-sensorineural-hearing-loss",
    "aria-label": "other causes of sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other causes of sensorineural hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some other, less common causes of acquired sensorineural hearing loss include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Circulatory issues"), " like diabetes, high blood pressure and blood clots"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Infections"), " such as meningitis, mumps, scarlet fever and measles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ototoxic drugs:"), " Certain prescription and over-the-counter medications can cause damage to the inner ear hair cells and nerve endings. These include NSAIDs (Non-Steroidal Anti-Inflammatory Drugs) like ibuprofen and aspirin, certain antibiotics and cancer medications, water pills and diuretics, quinine-based medications and others."), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "*Important note: if you’re concerned that hearing loss may be a side effect of one or more of your medications, consult your subscribing physician or pharmacist before making any changes.")), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Thyroid or hormonal issues")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Head or neck trauma")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ménière’s disease"), " (a disorder of the inner ear)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Other underlying health conditions")), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inherited or congenital hearing loss, happens during pregnancy and is far less common than acquired sensorineural hearing loss. Its causes include genetic syndromes, premature birth, infections passed from mother to fetus in utero (i.e. rubella or toxoplasmosis) and certain autoimmune diseases. Thankfully, newborns now undergo hearing tests before being discharged, so this kind of sensorineural hearing loss can be caught and treated early."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-options-for-sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-options-for-sensorineural-hearing-loss",
    "aria-label": "treatment options for sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment options for Sensorineural Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally speaking, sensorineural hearing loss is permanent and cannot be cured (except in the rare cases when it’s a symptom of another illness or condition that can be cured). But that doesn’t mean it should go untreated. Depending on the severity of SNHL, here are the two most common treatment options:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Hearing aids"), " — For mild to moderate cases of sensorineural hearing loss, hearing aids can be used to enhance speech clarity, amplify other sound of otherwise muffled speech, reduce background noise and more.  Hearing aid settings are all customized to your personal hearing loss pattern by a professional as determined by a hearing test."), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing tinnitus or phantom sounds like ringing or buzzing as a result of SNHL, hearing aids can also be programmed to offer relief."), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Cochlear implants"), " — For severe or profound cases of sensorineural hearing loss, these can be surgically embedded to pick up sounds and send electrical information to your auditory nerve."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss of any kind can seriously interfere with your quality of life, leading to feelings of isolation, frustration, embarrassment, higher stress and anxiety, and even psychological changes. But hearing aids and cochlear implants can go a long way to improving the experience of hearing and offering increased happiness and peace of mind."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-tests--next-steps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-tests--next-steps",
    "aria-label": "hearing tests  next steps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing tests & next steps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because there are so many known causes of SNHL, getting a series of ", React.createElement(_components.a, {
    href: "/hearing-loss/hearing-test/",
    className: "c-md-a"
  }, "hearing tests"), " and speaking with a doctor or professional about your personal health and hearing loss symptoms are the best ways to go about finding the source and learning about appropriate treatment options."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you go in to be evaluated for hearing loss by an ENT (Ear, Nose & Throat) specialist, audiologist or any hearing care professional, you may encounter one or more of these tests to help determine your unique hearing loss pattern as well as the cause:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Physical exam:"), " Your doctor will use a light to look inside to rule out conductive hearing loss issues like ear wax or inflammation from infection."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Pure-tone audiometry:"), " This test measures the softest or least audible tones a person can hear at various pitches, one ear at a time. The results from this test will show how well a patient can hear at different frequencies."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Weber & Rinne tests:"), " These are simple screening tests performed with a tuning fork, which can help determine whether you’re experiencing hearing issues in one or both ears, and whether your diagnosis is likely sensorineural hearing loss or conductive."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Otoacoustic emission test:"), " In this test, a doctor will insert a probe that emits sounds into your ear canal. It’s not painful and will be used to measure the inner ear’s ability to produce the soft sounds created by the vibration (emissions)."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This list is not exhaustive, and tests are administered on a case-by-case basis.  But the important takeaway is that all of these evaluations can be performed rather quickly and don’t require any preparation on behalf of the patient."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, if you think you or a loved one may be experiencing some form of sensorineural hearing loss, the first step is to make an appointment and get the process underway. The experts at hear.com can help."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They’re friendly, knowledgeable and equipped to help you every step of the way, from making that first appointment to getting fitted with your very first hearing aid. Call them at 786-520-2456 or tap the button to sign up and they’ll get in touch with you."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a no-risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
